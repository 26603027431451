import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translationEN.json';
import translationiD from './id/translationID.json';

const resources = {
    en: {
        translation: translationEN
    },
    id:{
        translation: translationiD
    }
}
i18n
    .use(initReactI18next) // passes i18n to react-i18next
    .init({
        resources,
        lng: 'id',

        keySeparator: false, // we dont use keys in form message.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })
export default i18n;