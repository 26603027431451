import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import {galleryCat1, galleryCat2, galleryCat3, galleryCat4, galleryCat5} from './data/data';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Spinner from './components/Spinner/Spinner';

const Home = lazy(()=> import('./containers/Home/Home'));
const Layout = lazy(()=> import('./layout/Layout'));
const Test = lazy(()=> import('./containers/Test/Test'));
const Services = lazy(()=> import('./containers/Services/Services'));
const Gallery = lazy(()=> import('./containers/Galeri/Gallery'));
const PictureGallery = lazy(()=> import('./components/PictureGallery/PictureGallery'));
const About = lazy(()=> import('./containers/About/About'));

function App() {
  return (
    <Suspense fallback={<Spinner/>} >
    <Layout>
      <ScrollToTop/>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='gallery' element={<Gallery/>}>
            <Route path='category-1' element={<PictureGallery category={galleryCat1}/>}/>
            <Route path='category-2' element={<PictureGallery category={galleryCat2}/>}/>
            <Route path='category-3' element={<PictureGallery category={galleryCat3}/>}/>
            <Route path='category-4' element={<PictureGallery category={galleryCat4}/>}/>
            <Route path='category-5' element={<PictureGallery category={galleryCat5}/>}/>
          </Route>
          <Route path='about' element={<About/>}/>
          <Route path='/test' element={<Test/>}/>
          <Route path="*" element={<Navigate to="/" replace />}/>
        </Routes>
    </Layout>
    </Suspense>
  );
}


export default App;
