import { Facility_MobilPengiriman,
    Facility_RuangAdmin,
    Facility_RuangHandmade,
    Facility_RuangLoading,
    Facility_RuangMeeting,
    Facility_RuangOven,
    Facility_RuangPengemasan,
    Facility_RuangPress,
    Facility_RuangSandwash,
    Facility_RuangWashing,
    Facility_FrontOffice,

    product_biobleach,
    product_biowash,
    product_destroy,
    product_enzyme,
    product_garment,
    product_sandwash,
    product_scrubwash,
    product_silicone,
    product_spray,
    product_tiedye,
    product_whisker,

    gallery_acc1,
    gallery_acc2,
    gallery_acc3,

    gallery_outer1,
    gallery_outer2,
    gallery_outer3,
    gallery_outer4,
    gallery_outer5,

    gallery_pants1,
    gallery_pants2,
    gallery_pants3,
    gallery_pants4,

    gallery_shirt,

    gallery_tshirt1,
    gallery_tshirt2,
    gallery_tshirt3,
    gallery_tshirt4,
    gallery_tshirt5,
    gallery_tshirt6,
    gallery_tshirt7,
    gallery_tshirt8,
    gallery_tshirt9,
    gallery_tshirt10,
    gallery_tshirt11,

    gallery_women1,
    gallery_women2,
    gallery_women3,
    gallery_women4,
    gallery_women5

}
    from "../assets/index"
export let productArray = [
    {
        "title":"Garment Wash",
        "desc":"garmentWash",
        "img":product_garment
    },
    {
        "title":"Silicone Wash",
        "desc":"siliconeWash",
        "img":product_silicone
    },
    {
        "title":"Enzyme Wash",
        "desc":"enzymeWash",
        "img":product_enzyme
    },
    {
        "title":"Bio Wash",
        "desc":"bioWash",
        "img":product_biowash
    },
    {
        "title":"Bio Bleach",
        "desc":"bioBleach",
        "img":product_biobleach
    },
    {
        "title":"Sandwash",
        "desc":"sandWash",
        "img":product_sandwash
    },
    {
        "title":"Scrub",
        "desc":"scrub",
        "img":product_scrubwash
    },
    {
        "title":"Tie Dye",
        "desc": "tieDye",
        "img":product_tiedye
    },
    {
        "title":"Destroy",
        "desc":"destroy",
        "img":product_destroy
    },
    {
        "title":"Spray",
        "desc":"spray",
        "img":product_spray
    },
    {
        "title":"Whisker",
        "desc":"whisker",
        "img":product_whisker
    },
    {
        "title":"Other",
        "desc":"other",
        "img":""
    }
]

export let galleryCat1 = [
    {
        "id":"1",
        "img":gallery_shirt
    },
    {
        "id":"2",
        "img":gallery_tshirt1
    },
    {
        "id":"3",
        "img":gallery_tshirt2
    },
    {
        "id":"4",
        "img":gallery_tshirt3
    },
    {
        "id":"5",
        "img":gallery_tshirt4
    },
    {
        "id":"6",
        "img":gallery_tshirt5
    },
    {
        "id":"7",
        "img":gallery_tshirt6
    },
    {
        "id":"8",
        "img":gallery_tshirt7
    },
    {
        "id":"9",
        "img":gallery_tshirt8
    },
    {
        "id":"10",
        "img":gallery_tshirt9
    },
    {
        "id":"11",
        "img":gallery_tshirt10
    },
    {
        "id":"12",
        "img":gallery_tshirt11
    },
]
export let galleryCat2 = [
    {
        "id":"1",
        "img":gallery_pants1
    },
    {
        "id":"2",
        "img":gallery_pants2
    },
    {
        "id":"3",
        "img":gallery_pants3
    },
    {
        "id":"4",
        "img":gallery_pants4
    },
]

export let galleryCat3 = [
    {
        "id":"1",
        "img":gallery_outer1,
    },
    {
        "id":"2",
        "img":gallery_outer2,
    },
    {
        "id":"3",
        "img":gallery_outer3,
    },
    {
        "id":"4",
        "img":gallery_outer4,
    },
    {
        "id":"5",
        "img":gallery_outer5,
    },
]
export let galleryCat4 = [
    {
        "id":"1",
        "img":gallery_women1,
    },
    {
        "id":"2",
        "img":gallery_women2,
    },
    {
        "id":"3",
        "img":gallery_women3,
    },
    {
        "id":"4",
        "img":gallery_women4,
    },
    {
        "id":"5",
        "img":gallery_women5,
    },
]
export let galleryCat5 = [
    {
        "id":"1",
        "img":gallery_acc1,
    },
    {
        "id":"2",
        "img":gallery_acc2,
    },
    {
        "id":"3",
        "img":gallery_acc3,
    },
]

export let facilityData = [
    {
        "id":"1",
        "img":Facility_RuangMeeting,
        "indo":"Ruangan Meeting",
        "en":'Meeting Room'

    },
    {
        "id":"2",
        "img":Facility_FrontOffice,
        "indo":"Front Office",
        "en":"Front Office"

    },
    {
        "id":"3",
        "img":Facility_RuangAdmin,
        "indo":"Ruangan Admin",
        "en":"Admin Office"

    },
    {
        "id":"4",
        "img":Facility_RuangLoading,
        "indo":"Area Loading",
        "en":"Loading Area"

    },
    {
        "id":"5",
        "img":Facility_RuangPengemasan,
        "indo":"Ruangan Pengemasan",
        "en":"Packaging Area"

    },
    {
        "id":"6",
        "img":Facility_RuangWashing,
        "indo":"Mesin Cuci",
        "en":"Washing Machine"

    },
    {
        "id":"7",
        "img":Facility_RuangOven,
        "indo":"Mesin Oven",
        "en":"Oven"

    },
    {
        "id":"8",
        "img":Facility_RuangPress,
        "indo":"Mesin Press",
        "en":"Press Machine"

    },
    {
        "id":"9",
        "img":Facility_RuangHandmade,
        "indo":"Ruang Handmade",
        "en":"Handmade Room"

    },
    {
        "id":"10",
        "img":Facility_RuangSandwash,
        "indo":"Mesin Sandwash",
        "en":"Sandwash Machine"

    },
    {
        "id":"11",
        "img":Facility_MobilPengiriman,
        "indo":"Mobil Pengiriman",
        "en":"Delivery Car"

    }
]

export let contents = [
    {
        "id":"homeSection1",
        "indo":{
            "title":"Jasa Pencucian Tekstil dan Garment Wash",
            "desc":"Kami melayani proses garment wash baik dari proses pencucian kain hingga handmade seperti tiedye."
        },
        "en":{
            "title":"Textile Laundry and Garment Wash Industry",
            "desc":"Oshwin Washing focusses mainly on textile laundry and garment wash industry, modified various types of fabric before distribute to retail."
        }
    },
    {
        "id":"homeSection2",
        "indo":{
            "title":"Siapa Kami ?",
            "desc":"CV. Oshwin Washing berdiri sejak tahun 2005 yang bermarkas di bandung jawa barat. kami berfokus dibidang jasa pencucian kain dan memodifikasi berbagai macam tipe kain sebelum didistribusikan ke retail. Untuk proses pengerjaannya pun kami menggunakan tenaga karyawan yang handal dan berpengalaman. Kami bekerja dengan mengutamakan intregitas dan kualitas karna kepuasan konsumen adalah proritas kami.",
            "content":["sejak","kapasitas perhari", "klien"]
        },
        "en":{
            "title":"Who are we ?",
            "desc":"Oshwin Washing Co. was established in 2005 and located at Bandung, Indonesia. we focuses mainly on textile laundry and garment wash industry, modified various types of fabric before distribute to retail. For the process, we have a reliable and experienced manpower. We serve by prioritizing integrity and quality. Customer satisfaction is our priority",
            "content":["since","capacity per day", "client"]
        }
    },
    {
        "id":"homeSection3",
        "indo":{
            "title":"Menyediakan Banyak Tipe Pencucian",
            "desc":"Lebih dari 10 tipe pencucian garment yang disediakan perusahaan ini, baik dari pencucian yang mudah seperti garment wash hingga maupun yang sulit dengan handmade"
        },
        "en":{
            "title":"Provides many types of washing",
            "desc":"More than 10 types of garment washing provided by this company, including easy washing such as garment wash to difficult ones with handmade."
        }
    },
    {
        "id":"homeSection4",
        "indo":{
            "title":"Mesin Berkualitas dan Tenaga Kerja yang handal",
            "desc":"Dengan opertor yang handal dan berpengalaman dipadukan dengan kualitas mesin yang baik, Oshwin Washing mampu memberikan kepuasan pelanggan dengan hasil yang sempurna."
        },
        "en":{
            "title":"Professional operators and good facilities",
            "desc":"With Professional operator and good facilities, We are able to provide customer satisfaction with perfect product."
        }
    },
    {
        "id":"homeSection5",
        "indo":{
            "title":"Pekerjaan Kami",
            "desc":"Sudah lebih dari 10 tahun kami telah menjalin kerja sama dengan banyak perusahaan-perusahaan besar di industri fashion dengan beberapa model dan beragam tipe tekstil."
        },
        "en":{
            "title":"Our Projects",
            "desc":"It's been more than 10 years we have collaborated with many big companies in the fashion industry with several models and various types of textiles."
        }
    },
    {
        "id":"serviceSection1",
        "indo":"Garment Wash merupakan teknik pencucian yang biasa diterapkan untuk melunturkan kanji pada kain agar menghasilkan kelembutan tanpa merubah warna aslinya.",
        "en":"Garment Wash is a washing technique that is usually applied to remove starch on fabrics to produce softness without changing the original color.",
    },
    {
        "id":"serviceSection2",
        "indo":"Proses pencucian ini menghasilkan kelembutan kain yang tahan lama, membantu ketahanan sobek, efek anti-pilling, stabilitas dimensi kain, dan juga membantu memudahkan kain untuk pemotongan dan proses penjahitan.",
        "en":"This washing process results in a long lasting softness of the fabric, helps with tear resistance, anti-pilling effect, dimensional stability of the fabric, and also helps make the fabric easier for cutting and sewing."
    },
    {
        "id":"serviceSection3",
        "indo":"Proses pencucian menggunakan softener kimia atau silikon & penambahan zat enzyme yang menghilangkan bulu – bulu halus di permukaan kain sehingga tekstur kain lebih lembut & licin.",
        "en":"The washing process uses chemical or silicone softeners and the addition of enzymes that remove hairs on the surface of the fabric so that the texture of the fabric is softer and smoother."
    },
    {
        "id":"serviceSection4",
        "indo":"Merupakan teknik pencucian pada produk berbahan kain denim yang biasa dilakukan untuk menghasilkan warna biru medium",
        "en":"It is a washing technique for products made from denim which is usually done to produce a medium blue color"
    },
    {
        "id":"serviceSection5",
        "indo":"Merupakan teknik pencucian pada produk berbahan kain denim yang biasa dilakukan dengan menggunakan zat pemutih yang bertujuan untuk melunturkan zat indigo (zat pewarna) pada kain sehingga warna yang dihasilkan warna biru muda atau biru langit.",
        "en":"Is a washing technique on products made from denim fabric which is usually done by using a bleaching agent which aims to dissolve the indigo substance (dye) on the fabric so that the resulting color is light blue or sky blue."
    },
    {
        "id":"serviceSection6",
        "indo":"Merupakan metode pencucian denim atau kaos yang prosesnya menggunakan bantuan pasir dan batu apung, sehingga permukaan bahan memudar menjadikan efek fading yang khas untuk mendapatkan nuansa vintage.",
        "en":"Is a method of washing denim or t-shirts, in which the process uses the help of sand and pumice, so that the surface of the material fades to create a distinctive fading effect to get a vintage feel."
    },
    {
        "id":"serviceSection7",
        "indo":"Merupakan metode untuk memberikan warna atau  kesan kotor atau vintage seperti sudah lama dipakai hampir mirip dengan metode spray, namun ini dengan media scrub.",
        "en":"Is a method to give a dirty or vintage color or impression like old jeans that almost similar to the spray method, but this is with a scrub media"
    },
    {
        "id":"serviceSection8",
        "indo":"Teknik ini merupakan teknik pewarnaan kain yang meliputi pelipatan, pemutaran, dan pengikatan yang menghasilkan motif yang unik dan penuh warna",
        "en":"This technique is a fabric dyeing technique that includes folding, twisting, and binding which produces a unique and colorful pattern"
    },
    {
        "id":"serviceSection9",
        "indo":"Destroy merupakan teknik perusakan kain di beberapa bagian dengan menggunakan alat khusus yang biasanya hanya untuk memunculkan benang-benang pada kain",
        "en":"Destroy is a technique of destroying fabric in several parts by using a special tool that is usually only to bring out the threads on the fabric"
    },
    {
        "id":"serviceSection10",
        "indo":"Spray merupakan teknik untuk memberikan warna pada beberapa bagian produk dengan menyemprotkan zat warna ke bagian yang diinginkan. sebagai contoh untuk memberikan kesan kotor atau sudah lama dipakai pada pakaian yang baru biasanya disemprot dengan warna karat.",
        "en":"Spray is a technique to give color to some parts of the product by spraying the dye to the desired part. For example, to give the impression of being dirty or having been worn for a long time, new clothes are usually sprayed with rust color."
    },
    {
        "id":"serviceSection11",
        "indo":"Whisker merupakan jenis washing yang biasa dilakukan untu membuat efek lipatan-lipatan pada bagian tertentu yang hasil efeknya terlihat seperti pakaian yang sudah pernah digunakan",
        "en":"Whisker is a type of washing that is usually done to create the effect of folds in certain parts which the effect looks like clothes that have been used."
    },
    {
        "id":"serviceSection12",
        "indo":"Untuk teknik pencucian lainnya anda dapat berkonsultasi lagi dengan kami melalui kontak atau alamat yang tertera",
        "en":"For other washing techniques you can consult with us through the contact or address below"
    },
    {
        "id":"aboutSection1",
        "indo":{
            "title":"Tentang Kami",
            "desc":"Kami berfokus dibidang jasa pencucian kain dan memodifikasi berbagai macam tipe kain sebelum didistribusikan ke retail. Untuk proses pengerjaannya pun kami menggunakan tenaga karyawan yang handal dan berpengalaman. Kami bekerja dengan mengutamakan intregitas dan kualitas karna kepuasan konsumen adalah proritas kami. CV. Oshwin Washing berdiri sejak tahun 2005 yang bermarkas di bandung jawa barat dengan Surat Izin Usaha Perdagangan (SIUP) tertera pada tanggal 9 Maret 2006. No : 00430/10-12/PK/III/2006 Notaris : Rahman,SH."
        },
        "en":{
            "title":"About Us",
            "desc":"We focuses mainly on textile laundry and garment wash industry, modified various types of fabric before distribute to retail. For the process, we have a reliable and experienced manpower. We serve by prioritizing integrity and quality. Customer satisfaction is our priority. Oshwin Washing Co. was established in 2005 and located at Bandung, Indonesia. With Business license No : 00430/10-12/PK/III/2006"
        }
    },
]

export default galleryCat1;