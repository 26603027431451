import React from 'react'
import { logo } from '../../assets'
import './Spinner.scss'

function Spinner() {
    return (
        <div className='Spinner'>
            <img src={logo} alt='logo'/>
            <h4>OSHWIN WASHING</h4>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Spinner